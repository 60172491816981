.servie-grid-image {
  filter: drop-shadow(-20px 20px 10px rgba(208, 216, 213, 0.456));
  max-height: 200px;
}
.service-Header {
  background-image: blue;
}
.proplist li {
  display: flex;
  column-gap: 1rem;
}

.image-container{
  position: relative;
  overflow: hidden;
}
.image-container .blobleft {
  position: absolute;
  left: 10%;
  top: 0;
  height: 300px;
  border-radius: 50%;
  width:  300px;
  background:linear-gradient(165deg, rgba(0, 0, 255, 0.5), rgba(255, 166, 0, 0.507));
}
.image-container .blobright {
  position: absolute;
  left: 30%;
  top: 0;
  height: 300px;
  border-radius: 50%;
  width:  300px;
  background:linear-gradient(165deg, rgba(0, 0, 255, 0.5), rgba(255, 166, 0, 0.507));
}