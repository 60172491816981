.navbar {
    padding: .2rem !important;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 -2px 5px rgba(209, 208, 208, 0.999);
}
.navbar img {
    filter: drop-shadow(-5px 5px 2px rgba(146, 140, 136, 0.316));
}

.dropdown:hover>.dropdown-menu {
  display: block;
  right: -100%;
  border: none;
  padding: 1rem 0;
  background:rgb(4, 99, 83);
}
.dropdown>.dropdown-toggle:active {
   pointer-events: none;
}

.dropdown .nav-link::after {
    content: '▾';
}
/* 
.nav-link {
    font-size: 1.2rem;
    color: rgb(9, 105, 102);
    transition: .1s ease-in;
} */
.nav-link:hover {
    color: rgb(238, 101, 10);
    transition: .1s ease-in;
}
#dropdownMenuButton .dropdown-menu {
        background-color: black !important;
}
.industries-drop-menue .row {
    width: fit-content;
    flex-wrap: nowrap;
}
.industries-drop-menue .row .col-6 {
    width: fit-content;
    
}
.industries-drop-menue .row  li a::before {
    content: '→ ';
    color: orange; 
    margin-right: .2rem;
} 
.dropdown-item {
    color: white !important;
}
.dropdown-item:hover {
    color: rgb(8, 88, 78) !important;
}
.dropdown-item::before {
    content: "→";
    margin-right: .5rem;
}

@media screen and (max-width:500px) {
    .dropdown-item::before {
        content: "";
        margin-right: 0rem;
    }
}
.drip-Arrow{
    position: absolute;
    height: 50px;
    width: 50px;
    background:rgb(4, 99, 83);
    rotate: 45deg;
    left: 30%;
    z-index: -1;
    top: -2px;
}

.nav-item .active {
    color: rgb(255, 81, 0) !important;
    text-shadow: -5px 5px 3px rgb(231, 230, 230);
}