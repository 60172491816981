.IndistryHeader {
    background:linear-gradient(65deg, rgba(0, 47, 255, 0.842), rgb(0, 255, 200));
}
.imageHeader {
    width: 120%;
    rotate: -25deg;
    position: absolute;
    /* filter: drop-shadow(-10px 10px 5px rgb(194, 192, 192)); */
}
@media screen and (max-width:768px) {
    .imageHeader {
        width: 100%;
        rotate: 0deg;
        position: relative;
    }
    .IndistryHeader {
        padding: .5rem 0 !important;
        background:linear-gradient(65deg, rgba(195, 0, 255, 0.788), rgb(0, 255, 200));
    } 
    .IndistryHeader h1 {
       text-align: center !important;
       font-size: 1.5rem !important;
    }
    .IndistryHeader h2 {
       text-align: center !important;
       font-size: 1rem !important;
       font-weight: 600;
       margin-bottom: 2rem;

    }
}

.industries-card  {
    background-color: rgb(5, 90, 69) !important;
    color: white !important;
    text-shadow: none !important;
    border-radius: 30px 0 30px 0 !important ;
}
.industries-card:hover  {
    display: flex;
    background-color: rgb(24, 230, 103)!important;
    color: white !important;
    text-shadow: none  !important;
    box-shadow: 0 0 3px 10px rgba(4, 94, 97, 0.103);
    border-radius: 0 30px 0 30px  !important ;
}
