/* Contact Section */
.contact {
    /* border: 1px solid gray; */
    border-radius: 10px;
    padding: 1rem;
    box-shadow: -3px 3px 10px  rgba(161, 156, 156, 0.288), 3px -3px 10px  rgba(179, 174, 174, 0.288);
    backdrop-filter: blur(10px);
    background-color: white;
}
input[type='text'], input[type='email'], textarea {
    border-bottom: 1px solid rgba(70, 63, 63, 0.226);
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    padding: .5rem 0;
}
.contact-query {
    column-gap: 1rem;
    row-gap: .1rem;
} 
.c-section {
    flex: 1;
}
.contact-block-1 {
    flex: 2;
    background: linear-gradient(125deg, rgba(11, 150, 108, 0.873) 10%, rgba(231, 60, 7, 0.866) 100%) ;
    padding: 1rem;
    border-radius: 10px;
    color: white;
}
.contact .left-top-block h2{
    text-shadow: -4px 4px 8px rgb(138, 131, 131);
}
.contact .left-top-block p{
    text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.197);
    color: rgba(255, 255, 255, 0.808);
    font-weight: 600;
    font-size: 1rem !important;
}
/* .contact .left-middle-block {
    display: flex;
} */
.contact-social-media li a {
    color: white;
    filter: drop-shadow(-4px 4px 4px rgba(122, 118, 118, 0.651));
}
.contact .left-Middle-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: .3rem;
}
.contact .left-Middle-block p{
    text-shadow: -4px 3px 2px rgba(44, 42, 42, 0.203);
    color: rgb(255, 255, 255);
}
.contact form label {
    color: var(--Primary-dark);
    font-weight: 600;
    font-size: 1rem;
    text-align: start;
}
.contact form input::placeholder, textarea::placeholder {
    color: rgba(231, 90, 25, 0.767);
    font-weight: 400;
    text-transform: lowercase;
}
.contact form input{
    color: rgba(231, 90, 25, 0.767);
    font-weight: 600;
}
.contact form textarea{
    text-transform: capitalize;
    color: rgba(231, 90, 25, 0.767);
    font-weight: 600;
    padding: .5rem;
}
.contact-query p {
    font-size: .9rem !important;
    font-weight: 600;
    color: var(--Primary-light);
    display: flex;
    column-gap: .5rem;
}
.submitmassage {
    border: none;
    padding: .5rem .3rem;
    backdrop-filter: blur(10px);
    background: linear-gradient(165deg, rgb(255, 81, 1), rgb(240, 77, 13), rgb(255, 115, 0));
    box-shadow: -4px 4px 5px rgba(158, 154, 154, 0.299), 4px -4px 5px rgba(158, 154, 154, 0.299);
    border-radius: 20px;
    color: rgb(233, 227, 227) !important;
    font-weight: 700 !important;
    letter-spacing: 3px;
    word-spacing: 3px;
    text-shadow: -4px 4px 5px rgba(54, 52, 52, 0.466);
}
.submitmassage:hover {
    background: linear-gradient(165deg, rgb(187, 74, 22), rgb(165, 51, 6), rgb(255, 139, 44));
}