.home-footer {
    /* box-shadow: 0 -2px 5px rgba(197, 191, 191, 0.322); */
    background: linear-gradient(rgba(0, 128, 111, 0.83), rgba(0, 128, 111, 0.63)), url(../../Assets/Images//Home/footer-bg.png);

}
.social-icons {
    height: 1.2rem;
    width: 1.2rem;
}
.home-footer h6 {
    color: rgb(245, 209, 198);
    font-weight: bold;
    font-size: medium !important;
}

.footer-links a::before {
    /* content: url(../../Assets/Images/Home/circle.svg); */
    margin-right: .5rem;
    opacity: 30%;
}
.footer-links a:hover::before {
    /* content: url(../../Assets/Images/Home/circle.svg); */
    margin-right: .5rem;
    opacity: 90%;
    filter: invert(0);
}
.footer-img {
    filter: drop-shadow(-8px 8px 1px rgba(255, 255, 255, 0.315));
}
.home-footer .social-media li a {
    color:rgb(0, 128, 90);
}
.home-footer .social-media li a:hover {
    color: var(--Primary-dark);
    filter: drop-shadow(-4px 4px 4px rgba(122, 118, 118, 0.651));
}
.home-footer .social-media {
   background: white;
   border-radius: 0 0 30px 30px ;
   box-shadow: -3px -3px 8px rgba(185, 185, 185, 0.856) inset, 3px -3px 8px rgba(185, 185, 185, 0.856) inset;
}
.home-footer a {
    color: rgb(252, 252, 252);
    font-weight: 600;
    
}
.home-footer a:hover {
    color:rgb(252, 211, 30);
    font-weight: 600;
    
}
.footer-links{
    display: flex;
    flex-direction: column;
}
.footer-links li a{
    text-decoration: none;
}
/* subfooetr */
.subfooter {
    box-shadow: 0 -2px 2px rgba(219, 217, 217, 0.123);
}
/* 
.social-media {
    transform: translateX(-50%);
    transform: translateY(-50%);
    background-color: orangered;
    position: absolute;
} */

.gototop {
    color: rgb(114, 3, 36);
    animation-name:updownanimate ;
    animation-duration: 2s;
    animation-delay: 0ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    scroll-behavior: smooth ;
}

@keyframes updownanimate {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}
.subfooter {
    background-color: white;
    box-shadow: 0 0 3px 5px rgba(128, 128, 128, 0.39) inset;
    border-radius: 50px 50px 0px 00px;
    padding: 0 1rem;
}
.addresslinks a {
    list-style: none !important;
    text-decoration: none !important;
}